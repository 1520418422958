export default defineNuxtRouteMiddleware((to, from) => {
  const currentUser = useLoginAccount()
  if ((!currentUser.value || !currentUser.value.isLogin) && to.path !== '/login') {
    return { path: '/login' }
  }

  const userId = to.params.userId as string
  if (userId && userId !== String(currentUser.value.userId)) {
    useSession().clear()
    return { path: '/login' }
  }

  // ルートアクセスは強制画面遷移
  const loginDialog = useLoginDialog()
  if (!loginDialog.value && currentUser.value.userId && to.fullPath === '/') {
    return { path: `/apps/${currentUser.value.userId}/dashboard` }
  }
})
